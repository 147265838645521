import {
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  getAuth,
} from "firebase/auth";
import { useEffect } from "react";
import { selectUser } from "../features/user/userSlice";
import { useAppSelector, useAppDispatch } from "../app/hooks";

const Profile = () => {
  const auth = getAuth();
  const user = useAppSelector(selectUser);

  const onSolvedRecaptcha = (recaptchaVerifier: RecaptchaVerifier) => {
    if (user) {
      multiFactor(user)
        .getSession()
        .then(function (multiFactorSession) {
          const phoneNumber =
            "+1" +
            prompt(
              "Enter phone number with area code, with no spaces or hyphens (4125551212)"
            );
          // Specify the phone number and pass the MFA session.
          const phoneInfoOptions = {
            phoneNumber,
            session: multiFactorSession,
          };
          const phoneAuthProvider = new PhoneAuthProvider(auth);
          // Send SMS verification code.
          return phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          );
        })
        .then(function (verificationId) {
          const verificationCode = prompt("Enter verification code");

          const cred = PhoneAuthProvider.credential(
            verificationId,
            verificationCode || ""
          );
          const multiFactorAssertion =
            PhoneMultiFactorGenerator.assertion(cred);

          // Complete enrollment.
          return multiFactor(user).enroll(multiFactorAssertion, "Dapprio");
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container-id",
      {
        size: "invisible",
        callback: function (response: any) {
          onSolvedRecaptcha(recaptchaVerifier);
        },
      },
      auth
    );
    recaptchaVerifier.verify();
  }, []);

  return <div id="recaptcha-container-id"></div>;
};

export default Profile;
