import Button from "@mui/material/Button";
import LoginIcon from "@mui/icons-material/Login";
import { useAppSelector, useAppDispatch } from "../app/hooks";
import { selectJwt, setUser, setJwt } from "../features/user/userSlice";
import jwt_decode from "jwt-decode";
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  getMultiFactorResolver,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const getRecaptchaContainerId = () => `recaptcha_${new Date().getTime()}`;

export const SignInWithGoogle = () => {
  /**
   * failed login attempts can not re-use the same RecaptchaVerifier instance.
   * work around strategy: generate a new ID on failure and add it to this list,
   * then render a container element for only the last element of the list, thereby
   * ensuring that the RecaptchaVerifier is always re-rendered freshly into
   * a brand new container instance
   */
  const [containerIds, setContainerIds] = useState([getRecaptchaContainerId()]);
  const user = useAppSelector(selectJwt);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnClick = async () => {
    const provider = new GoogleAuthProvider();

    var config = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    };

    const app = initializeApp(config);
    const auth = getAuth(app);

    // log in without mfa
    try {
      const result = await signInWithPopup(auth, provider);
      handleSuccessfulSignIn(result);
    } catch (error: any) {
      // log in with mfa
      if (error.code == "auth/multi-factor-auth-required") {
        const resolver = getMultiFactorResolver(auth, error);
        const recaptchaVerifier = new RecaptchaVerifier(
          containerIds[containerIds.length - 1],
          { size: "invisible" },
          auth
        );
        const phoneInfoOptions = {
          multiFactorHint: resolver.hints[0],
          session: resolver.session,
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        const verificationId = await phoneAuthProvider.verifyPhoneNumber(
          phoneInfoOptions,
          recaptchaVerifier
        );
        const verificationCode = prompt("Enter verification code");
        const cred = PhoneAuthProvider.credential(
          verificationId,
          verificationCode || ""
        );
        const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);

        try {
          const userCredential = await resolver.resolveSignIn(
            multiFactorAssertion
          );
          handleSuccessfulSignIn(userCredential);
        } catch (error: any) {
          if (error.code == "auth/invalid-verification-code") {
            setContainerIds((current) => [
              ...current,
              getRecaptchaContainerId(),
            ]);
            alert("Invalid verification code");
          }
        }
      } else if (error.code == "auth/wrong-password") {
        alert("Wrong password");
      }
    }
  };

  const handleSuccessfulSignIn = (result: any) => {
    // credential will contain both an OAuth ID token and access token
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential?.idToken;
    if (!token) return;
    dispatch(setJwt(token));
    dispatch(setUser(result.user));
  };

  const handleOnClickEmail = async (event: React.SyntheticEvent | Event) => {
    navigate("/profile");
  };

  if (user) {
    const decoded: { email: string } = jwt_decode(user);
    return <div onClick={handleOnClickEmail}>{decoded.email}</div>;
  }

  return (
    <>
      <Button onClick={handleOnClick} startIcon={<LoginIcon />} color="inherit">
        Login
      </Button>
      {containerIds
        .filter((value, index, array) => index == array.length - 1)
        .map((value) => (
          <div key={value} id={value} style={{ display: "none" }}></div>
        ))}
    </>
  );
};
