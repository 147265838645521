import { Container } from "@mui/system";
import { useState, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ContractRecord } from "./types";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import { useAppSelector } from "../app/hooks";
import { selectJwt } from "../features/user/userSlice";

const Dashboard = () => {
  const user = useAppSelector(selectJwt);
  const navigate = useNavigate();
  const [results, setResults] = useState<ContractRecord[]>([]);
  const [query, setQuery] = useState<string>("");

  const handleQueryChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setQuery(value);
  };

  const handleSearchClick = async () => {
    // TODO move to api layer
    const response = await fetch("/api/auth/search", {
      method: "post",
      body: query,
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });
    // TODO centralize
    if (response.status === 401) {
      const text = await response.text();
      console.log(text);
      return;
    }

    const results = await response.json();
    setResults(results);
  };

  const handleNewContractClick = async () => {
    navigate("/create-contract");
  };

  const handleEditContractClick = (id: string) => () => {
    navigate(`/create-contract/${id}`);
  };

  return user ? (
    <>
      <Box sx={{ p: 1, m: 1 }}>
        <TextField
          value={query}
          onChange={handleQueryChange}
          label="Search Contract Description"
          variant="outlined"
          sx={{ marginRight: 2, width: 500 }}
        />
        <Button
          sx={{ marginRight: 2 }}
          onClick={handleSearchClick}
          variant="contained"
          startIcon={<SearchIcon />}
        >
          Search
        </Button>
        <Button
          onClick={handleNewContractClick}
          variant="contained"
          startIcon={<AddIcon />}
        >
          New Contract
        </Button>
      </Box>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <List sx={{ width: "50%", bgcolor: "background.paper" }}>
          {results.map(({ id, contract: { address, description } }) => (
            <ListItem
              key={id}
              secondaryAction={
                <IconButton
                  aria-label="comment"
                  onClick={handleEditContractClick(`${id}`)}
                >
                  <EditIcon />
                </IconButton>
              }
            >
              <ListItemText primary={address} secondary={description} />
            </ListItem>
          ))}
        </List>
      </Container>
    </>
  ) : (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bottom: "0px",
        top: "50px",
        width: "100%",
        position: "absolute",
      }}
    >
      <i>Not signed in</i>
    </div>
  );
};

export default Dashboard;
