import { useState, ChangeEvent, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import PublishIcon from "@mui/icons-material/Publish";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { ContractRecord, StakeHolder } from "./types";
import { useAppSelector } from "../app/hooks";
import { selectJwt } from "../features/user/userSlice";

interface CreateContractProps {
  toast: Function;
  address: string;
  data?: ContractRecord;
}

interface CompiledContract {
  abi: AbiItem[];
  bytecode: string;
}

const saveContract = async (contract: ContractRecord, user: string | null) => {
  // TODO move to api layer
  const response = await fetch("/api/auth/write", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${user}`,
    },
    body: JSON.stringify(contract),
  });
  // TODO centralize
  if (response.status === 401) {
    const text = await response.text();
    console.log(text);
    return;
  }
  const body = await response.json();
  return body;
};

const CreateContract = (props: CreateContractProps) => {
  const { toast } = props;
  const count = useRef(1);
  const navigate = useNavigate();
  const user = useAppSelector(selectJwt);

  const params = useParams();

  const [data, setData] = useState<ContractRecord>({
    id: null,
    contract: {
      address: null,
      description: "",
      amount: "0.0",
      stakeHolders: [
        {
          name: "contract-0",
          owner: "",
          amount: "0.0",
        },
      ],
    },
  });

  useEffect(() => {
    if (params.id) {
      (async () => {
        // TODO move to api layer
        const response = await fetch(`/api/auth/read/${params.id}`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        // TODO centralize
        if (response.status === 401) {
          const text = await response.text();
          console.log(text);
          return;
        }
        const [data] = await response.json();
        setData(data);
      })();
    }
  }, [params.id, user]);

  const [compiledContract, setCompiledContract] = useState<CompiledContract>({
    abi: new Array<AbiItem>(),
    bytecode: "",
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      // TODO move to api layer
      const response = await fetch("/api/auth/contract/DapprioV1", {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      // TODO centralize
      if (response.status === 401) {
        const text = await response.text();
        console.log(text);
        return;
      }
      const data = await response.json();
      setCompiledContract(data);
    })();
  }, []);

  const handleAddressTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const index = data.contract.stakeHolders.findIndex(
      (item) => item.name === name
    );
    const updated = [...data.contract.stakeHolders];
    updated.splice(index, 1, { ...updated[index], owner: value });
    setData({ ...data, contract: { ...data.contract, stakeHolders: updated } });
  };

  const handleAmountTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const { stakeHolders } = data.contract;
    const index = stakeHolders.findIndex((item) => item.name === name);
    const updated = [...stakeHolders];
    updated.splice(index, 1, { ...updated[index], amount: value });
    setData({ ...data, contract: { ...data.contract, stakeHolders: updated } });
  };

  const handleContractAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      contract: { ...data.contract, amount: event.target.value },
    });
  };

  const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => {
    setData({
      ...data,
      contract: { ...data.contract, description: event.target.value },
    });
  };

  const handleSaveClick = async () => {
    const { toast } = props;
    const result = await saveContract(data, user);
    setData(result);
    toast("Saved successfully");
  };

  const handleAddClick = () => {
    setData((current) => ({
      ...data,
      contract: {
        ...data.contract,
        stakeHolders: [
          ...current.contract.stakeHolders,
          { name: `contract-${count.current}`, owner: "", amount: "0.0" },
        ],
      },
    }));

    count.current = count.current + 1;
  };

  const handleRemoveClick = (index: number) => (event: any) => {
    const { stakeHolders } = data.contract;
    const updated = [...stakeHolders];
    updated.splice(index, 1);
    setData({ ...data, contract: { ...data.contract, stakeHolders: updated } });
  };

  const handleCreateClick = async () => {
    const { address } = props;
    const { amount, stakeHolders } = data.contract;
    if (
      !amount.trim() ||
      stakeHolders.find(({ owner }) => !owner) ||
      !address
    ) {
      return;
    }
    setLoading(true);

    // TODO move to api layer
    // write to ipfs
    const response = await fetch("/api/auth/ipfs", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user}`,
      },
      method: "POST",
      body: JSON.stringify(
        {
          amount,
          stakeHolders,
        },
        null,
        2
      ),
    });
    // TODO centralize
    if (response.status === 401) {
      const text = await response.text();
      console.log(text);
      return;
    }

    let uri;
    try {
      const result = await response.json();
      uri = result.uri;
    } catch (err) {
      console.log(`Failed writing to IPFS: ${err}`);
      return;
    }

    console.log(`IPFS URI: ${uri}`);

    // write to blockchain
    const deployedContractAddress = await deployContract(
      amount,
      stakeHolders,
      uri,
      compiledContract,
      address
    );

    // error
    if (!deployedContractAddress) return;

    // compute updated local object
    const updated = {
      ...data,
      contract: {
        ...data.contract,
        address: deployedContractAddress,
      },
    };

    // update local object state
    setData(updated);

    // update contract address in the database
    saveContract(updated, user);

    await toast(deployedContractAddress);
    setLoading(false);
  };

  const handleCancelClick = async () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        p: 1,
        m: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          p: 1,
          m: 1,
        }}
      >
        <Button
          sx={{ marginTop: 1 }}
          onClick={handleAddClick}
          variant="contained"
          startIcon={<AddIcon />}
          fullWidth={true}
          style={{ justifyContent: "flex-start" }}
          // already deployed to blockchain
          disabled={data.contract.address !== null}
        >
          Add Stakeholder
        </Button>
        <Button
          sx={{ marginTop: 1 }}
          onClick={handleSaveClick}
          variant="contained"
          startIcon={<SaveIcon />}
          fullWidth={true}
          style={{ justifyContent: "flex-start" }}
          // already deployed to blockchain
          disabled={data.contract.address !== null}
        >
          Save Contract
        </Button>
        <LoadingButton
          color="success"
          onClick={handleCreateClick}
          loading={loading}
          loadingPosition="start"
          startIcon={<PublishIcon />}
          variant="contained"
          sx={{ marginTop: 1 }}
          fullWidth={true}
          style={{ justifyContent: "flex-start" }}
          // not saved or already deployed to blockchain
          disabled={data.id === null || data.contract.address !== null}
        >
          <span>Deploy Contract</span>
        </LoadingButton>
        <Button
          sx={{ marginTop: 1 }}
          variant="contained"
          onClick={handleCancelClick}
          color="error"
          startIcon={<CancelIcon />}
          fullWidth={true}
          style={{ justifyContent: "flex-start" }}
        >
          Cancel
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          p: 1,
          m: 1,
        }}
      >
        <TextField
          value={data.contract.description}
          onChange={handleDescriptionChange}
          label="Description"
          variant="outlined"
          multiline
        />
        <TextField
          value={data.contract.amount}
          onChange={handleContractAmountChange}
          label="Total Contract Amount"
          variant="outlined"
          type="number"
          sx={{ width: 200, marginTop: 3 }}
        />
        {data.contract.stakeHolders.map((item, index) => {
          return (
            <div key={item.name} style={{ paddingTop: 20 }}>
              <TextField
                name={item.name}
                value={item?.owner}
                onChange={handleAddressTextChange}
                label="Stakeholder Address"
                variant="outlined"
                sx={{ marginRight: 2, width: 500 }}
              />
              <TextField
                name={item.name}
                value={item?.amount}
                onChange={handleAmountTextChange}
                label="Amount"
                type="number"
                variant="outlined"
                sx={{ marginRight: 2, width: 200 }}
              />
              {data.contract.stakeHolders.length > 1 && (
                <Button
                  onClick={handleRemoveClick(index)}
                  sx={{ marginRight: 2 }}
                  variant="contained"
                >
                  Remove
                </Button>
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default CreateContract;

const deployContract = async (
  totalContractAmount: string,
  stakeHolders: StakeHolder[],
  tokenURI: string,
  compiledContract: CompiledContract,
  accountAddress: string
) => {
  const { ethereum } = window as any;
  // check for injected web3 instance
  if (ethereum) {
    const web3 = new Web3(ethereum);
    const contract = new web3.eth.Contract(compiledContract.abi as AbiItem[]);
    const config = {
      from: accountAddress,
      // the compiled bytecode of the contract
      data: compiledContract.bytecode,
      // constructor args
      arguments: [
        stakeHolders.map(({ owner, amount }) => ({
          owner,
          amount: web3.utils.toWei(amount, "ether"),
        })),
        tokenURI,
      ],
      value: web3.utils.toWei(totalContractAmount, "ether"),
    };
    try {
      const receipt = await contract.deploy(config).send(config);
      const { address } = receipt.options;
      return address;
    } catch (e) {
      console.log("Rejected");
    }
  } else {
    console.log("Could not find an injected ethereum instance");
  }
};
