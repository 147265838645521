import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";

export interface UserState {
  jwt: string | null;
  user: any | null;
}

const initialState: UserState = {
  jwt: null,
  user: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setJwt: (state, action: PayloadAction<string>) => {
      state.jwt = action.payload;
    },
    setUser: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
  },
});

export const { setUser, setJwt } = userSlice.actions;

export const selectJwt = (state: RootState) => state.user.jwt;

export const selectUser = (state: RootState) => state.user.user;

export default userSlice.reducer;
