import React, { useState } from "react";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import WalletIcon from "@mui/icons-material/Wallet";

import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import CreateContract from "./CreateContract";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Dashboard from "./Dashboard";
import { SignInWithGoogle } from "./SignInWithGoogle";
import Profile from "./Profile";
import { useAppDispatch } from "../app/hooks";

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function Main() {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedAccountAddress, setSelectedAccountAddress] = useState("");
  const dispatch = useAppDispatch();

  const toast = async (text: string) => {
    setMessage(text);
    setOpen(true);
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const connectMetamask = async () => {
    const { ethereum } = window as any;
    if (ethereum) {
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });
      setSelectedAccountAddress(accounts[0]);
    } else {
      console.log("Could not find an injected ethereum instance");
    }
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <BrowserRouter>
          <AppBar position="static">
            <Toolbar>
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                Dapprio
              </Typography>
              {!selectedAccountAddress && (
                <Button
                  color="inherit"
                  onClick={connectMetamask}
                  sx={{ marginRight: 2 }}
                  startIcon={<WalletIcon />}
                >
                  Connect Wallet
                </Button>
              )}
              <SignInWithGoogle />
            </Toolbar>
          </AppBar>
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route
              path="/create-contract"
              element={
                <CreateContract
                  toast={toast}
                  address={selectedAccountAddress}
                />
              }
            />
            <Route
              path="/create-contract/:id"
              element={
                <CreateContract
                  toast={toast}
                  address={selectedAccountAddress}
                />
              }
            />
            <Route path="/profile" element={<Profile />} />
          </Routes>
        </BrowserRouter>
      </Box>
      <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Main;
